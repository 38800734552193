import React from 'react'
import Layout from '../../containers/Layout'
import { Listing } from 'web-components'
import { imageCDNUrl } from '../../helpers'

import products from '../../config/text/products.json'

const syrup = products.syrup

class Syrup extends React.Component {
  render () {
    return (
      <Layout heroSrc='heros/shott-caramel-latte.jpg' heroText={syrup.title} title={syrup.meta.title}>
        <div className='syrup container'>
          <div className='row'>
            <div className='col-xs-12'>
              {syrup.description.map(d => <p>{d}</p>)}
            </div>
          </div>
          <div className='row'>
            {syrup.products.map(product => {
              return <Listing
                className='p-0'
                key={product.name}
                description={product.description}
                footnote={product.footnote}
                halfWidth
                img={{
                  ...product.img,
                  src: imageCDNUrl(product.img.src)
                }}
                name={product.name}
                {...{ points: product.points }}
              />
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Syrup
